// src/screens/Property/PropertiesLanding/PropertyLandingMap/PropertyMapWrapper/PropertyMap/utilities/mapboxLayerHelpers.jsx

import React, { useContext } from 'react';
import { GeoJSONLayer } from 'react-mapbox-gl';
import { featureCollection } from '@turf/helpers';
import { isEmpty } from 'lodash';

import { Context } from 'components/Store';
import formatNumber from 'helpers/formatNumber';
import { getCentroidGeoJSON } from 'screens/Property/helpers/mapApiHelpers';

export const SELECTED_LAYER_TYPE = 'selected';
export const FOCUSED_LAYER_TYPE = 'focused';
export const CUSTOM_LAYER_TYPE = 'custom';

export const conditionFieldWithCropZones = [
  'all',
  ['==', ['get', '$landType'], 'field'],
  ['>', ['length', ['get', 'cropzones']], 0]
];
export const conditionLayerSelected = [
  '==',
  ['get', '$layer'],
  SELECTED_LAYER_TYPE
];
export const conditionLayerCustom = [
  '==',
  ['get', '$layer'],
  CUSTOM_LAYER_TYPE
];

export const symbolLayoutTemplate = {
  'text-anchor': 'center',
  'text-justify': 'center',
  'text-variable-anchor': ['center'],
  'text-radial-offset': 0,
  'text-size': 10,
  'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
  'text-allow-overlap': true,
  'symbol-sort-key': ['get', 'sortKey']
};

export const customSymbolLayout = {
  'text-field': [
    'case',
    conditionFieldWithCropZones,
    '',
    ['get', 'customLabel']
  ],
  ...symbolLayoutTemplate
};

export const legendSymbolLayout = {
  'text-field': ['get', 'cropZoneLegendKey'],
  ...symbolLayoutTemplate
};

export const customSymbolPaint = {
  'text-color': 'white',
  'text-halo-color': 'black',
  'text-halo-width': 1
};

export const buildCustomLabel = (
  properties,
  mapLabelConfig,
  fields,
  nameOnly
) => {
  const customLabel = [];
  if (nameOnly) {
    return properties.name;
  }

  if (properties.$landType !== 'cropzone') return '';

  const parentField = fields.find(
    field => field.id === properties.$parentFieldId
  );

  if (mapLabelConfig.farmNames) {
    customLabel.push(parentField?.farmName);
  }
  if (mapLabelConfig.fieldNames) {
    customLabel.push(parentField?.name);
  }
  if (mapLabelConfig.cropZoneNames) {
    customLabel.push(properties.name);
  }
  if (mapLabelConfig.assignedCropType && properties.crop) {
    customLabel.push(properties.crop.name);
  }
  if (
    mapLabelConfig.area &&
    (properties.reportedArea || properties.boundaryArea)
  ) {
    customLabel.push(
      formatNumber(properties.reportedArea || properties.boundaryArea, 'ac')
    );
  }
  return customLabel.join('\n');
};

export const CustomLayers = ({
  geoJSONArr,
  stylesArr,
  onClickShape,
  isMultipleSelect,
  showLegendLabels
}) => {
  const [{ isExportPDFMapsActive, exportPDFMapsStep }] = useContext(Context);
  return geoJSONArr.map((features, idx) => {
    if (!stylesArr[idx] || isEmpty(stylesArr[idx])) return null;

    const uniqueGeometries = new Set();

    let sortedFeatures = features.sort((a, b) => {
      if (!a.properties.sortKey) return -1;
      if (!b.properties.sortKey) return 1;
      return b.properties.sortKey - a.properties.sortKey;
    });

    // In export preview, remove labels from overlapping crop zones
    if (isExportPDFMapsActive && exportPDFMapsStep === 1) {
      sortedFeatures = sortedFeatures
        // Reverse the array so that the highest sortKey feature keeps its label
        .reverse()
        // Remove labels from crop zones with the same geometry as a previous crop zone
        .map(feature => {
          if (feature.properties.$landType !== 'cropzone') return feature;

          const geometryString = JSON.stringify(feature.geometry);
          const newFeature = { ...feature };
          if (uniqueGeometries.has(geometryString)) {
            newFeature.properties.customLabel = undefined;
          } else {
            uniqueGeometries.add(geometryString);
          }
          return newFeature;
        })
        // Reverse the array back to its original order
        .reverse();
    }

    const fc = featureCollection(sortedFeatures);
    const centroids = getCentroidGeoJSON(fc);
    const { fillColor, fillOutlineColor } = stylesArr[idx];

    const fillPaint = isMultipleSelect
      ? {
          'fill-color': [
            'case',
            conditionFieldWithCropZones,
            'rgba(20, 21, 28, 0.3)',
            ['has', 'cropColor'],
            ['get', 'cropColor'],
            fillColor
          ]
        }
      : {
          'fill-color': fillColor,
          'fill-outline-color': fillOutlineColor
        };

    const linePaint = isMultipleSelect
      ? {
          'line-color': [
            'case',
            ['has', 'cropColor'],
            'transparent',
            conditionFieldWithCropZones,
            '#14151C',
            fillOutlineColor
          ],
          'line-width': ['case', ['has', 'cropColor'], 0, 2]
        }
      : {};

    const symbolLayout = showLegendLabels
      ? legendSymbolLayout
      : customSymbolLayout;

    if (isExportPDFMapsActive) {
      symbolLayout['text-size'] = exportPDFMapsStep === 0 ? 14 : 10;
    }

    return (
      <>
        <GeoJSONLayer
          key={`line-${features[0]?.properties?.id || idx}`}
          data={fc}
          type="line"
          linePaint={linePaint}
        />
        <GeoJSONLayer
          key={`fill-${features[0]?.properties?.id || idx}`}
          data={fc}
          type="fill"
          fillOnClick={onClickShape}
          fillPaint={{
            ...fillPaint,
            'fill-antialias': true
          }}
        />
        <GeoJSONLayer
          key={`symbol-${features[0]?.properties?.id || idx}`}
          data={centroids}
          symbolLayout={symbolLayout}
          symbolPaint={customSymbolPaint}
        />
      </>
    );
  });
};

export default CustomLayers;
