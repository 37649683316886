/* eslint-disable no-unused-vars */
import React, { useState, useCallback, useEffect, useContext } from 'react';
import { Context } from 'components/Store';
import { SimpleModal, Spinner } from '@agconnections/grow-ui';

import { useToast } from 'components/ToastContainer';
import useCropData from 'hooks/useCropData';
import usePropertyFilters from 'hooks/usePropertyFilters';
import {
  LAST_COORDINATES_STORED,
  PROPERTY_VIEW_MODE
} from 'screens/Property/helpers/constants';
import {
  MASS_ASSIGNER_SUCCESS_COUNTER,
  RESET_MASS_ASSIGNER,
  SET_IS_SORT_BY_CROPS
} from 'reducers/reducer';
import createAction from 'helpers/createAction';
import PropertiesTree from './PropertiesTree';
import PropertyLandingMap from './PropertyLandingMap';
import PropertyTable from './PropertyTable';
import CreatePropertyModal from './CreatePropertyModal';
import PropertyTopHeader from './PropertyTopHeader/index';
import ImportPropertyModal from './ImportPropertyModal';
import EditFarm from './EditFarm';
import PropertyBreadcrumb from './components/PropertyBreadcrumb';
import MassAssignerHeader from './components/MassAssignerHeader';
import PropertyContributionMargin from './PropertyContributionMargin/PropertyContributionMargin';
import PropertyCostAnalysis from './PropertyCostAnalysis';
import PropertyLandingContext, { PropertyLandingProvider } from './context';
import './index.css';

const fftWidth = '338px';

const PropertiesLanding = () => {
  return (
    <PropertyLandingProvider>
      <PropertiesLandingContent />
    </PropertyLandingProvider>
  );
};

const PropertiesLandingContent = () => {
  const {
    params,
    // conditions
    isCostAnalysisViewEnabled,
    isPropertyDetailsEnabled,
    isHugeOrganization,
    //
    selectedProperty,
    viewMode,
    onViewModeChange,
    onDrawFieldsClick,
    // map data
    isLoadingFields,
    propertyLandingPageData,
    fieldsAndAreasGeoJSONCollection,
    setGeoJSONCollectionFeatures,
    filterFeatureCollectionByParentId,
    fetchProperties
  } = useContext(PropertyLandingContext);

  const [
    {
      isGlobalCropSeasonLoading,
      isFieldImportedSuccessful,
      isLoadingOrg,
      isMassAssignerActive,
      isSidebarOpen,
      fieldsToMassiveAssign,
      massAssignerSuccessCounter,
      isExportPDFMapsActive,
      importedFields,
      loggedInUserOrgPermission,
      organization
    },
    dispatch
  ] = useContext(Context);

  const { crops } = useCropData();
  const { filteredProperties, setPropertiesToFilter } = usePropertyFilters(
    isHugeOrganization
  );
  const toast = useToast();
  const [localSelectedProperty, setLocalSelectedProperty] = useState([
    filteredProperties
  ]);
  const [edited, setEdited] = useState(false);
  const [enableEdit, setEnableEdit] = useState(false);
  const [cancelEditModalVisible, setCancelEditModalVisible] = useState(false);
  const [selectedCrop, setSelectedCrop] = useState();
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [showImportModal, setShowImportModal] = useState(
    !!isFieldImportedSuccessful
  );

  PropertiesLanding.zoomPropertyOut = () => {
    setGeoJSONCollectionFeatures(
      filterFeatureCollectionByParentId(
        fieldsAndAreasGeoJSONCollection,
        selectedProperty.id,
        fieldsToMassiveAssign
      )
    );
  };

  const formPanel = {
    Farm: (
      <EditFarm
        farmName={selectedProperty.name}
        farmId={selectedProperty.id}
        reloadFarmData={fetchProperties}
        setView={onViewModeChange}
      />
    )
  };

  const onUploadFileMode = () => setShowImportModal(prev => !prev);
  const openCreateModalFn = useCallback(() => setOpenCreateModal(true), []);

  useEffect(() => {
    PropertiesLanding.zoomPropertyOut();
  }, [selectedProperty.id, isMassAssignerActive, fieldsToMassiveAssign]);
  const displayHeader = !selectedProperty?.isEditing && !isExportPDFMapsActive;
  useEffect(() => {
    if (isGlobalCropSeasonLoading || !organization?.id) return;
    fetchProperties(
      { seasonIds: JSON.parse(localStorage.getItem('selectedCropSeasons')) },
      params
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, organization?.id, isGlobalCropSeasonLoading]);

  useEffect(() => {
    return () => {
      dispatch({ type: RESET_MASS_ASSIGNER });
      localStorage.removeItem(LAST_COORDINATES_STORED);
    };
  }, [dispatch]);

  useEffect(() => {
    setPropertiesToFilter(propertyLandingPageData?.properties);
    setLocalSelectedProperty(filteredProperties);
  }, [
    propertyLandingPageData?.properties,
    filteredProperties,
    setPropertiesToFilter
  ]);

  useEffect(() => {
    setLocalSelectedProperty(filteredProperties);
  }, [filteredProperties]);

  useEffect(() => {
    if (isExportPDFMapsActive === true) {
      return;
    }
    createAction(dispatch, SET_IS_SORT_BY_CROPS, false);
  }, [isExportPDFMapsActive, dispatch]);

  useEffect(() => {
    if (Object.keys(massAssignerSuccessCounter).length) {
      const {
        toastType,
        toastTitle,
        toastDescription
      } = massAssignerSuccessCounter;

      const adjustedToastType = toastType === 'warning' ? 'warn' : toastType;
      toast[adjustedToastType](toastTitle, { content: toastDescription });
      setTimeout(() => {
        dispatch({
          type: MASS_ASSIGNER_SUCCESS_COUNTER,
          payload: {}
        });
      }, 5000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [massAssignerSuccessCounter]);

  return (
    <>
      <div
        data-testid="properties-landing"
        className={`w-full h-full overflow-hidden ${
          isSidebarOpen ? 'sidebar-open' : ''
        }`}
      >
        <PropertyBreadcrumb
          // Disabled as it is not working; it will be fixed in CWFWEB-7792
          disabledPropertyChange
        />

        {openCreateModal && (
          <CreatePropertyModal
            open={openCreateModal}
            closeModal={setOpenCreateModal}
            drawFieldsClick={selected =>
              onDrawFieldsClick(selected, filteredProperties)
            }
            uploadFilesClick={onUploadFileMode}
          />
        )}

        {isLoadingOrg || isGlobalCropSeasonLoading ? (
          <Spinner />
        ) : (
          <div className="w-full h-full">
            {isMassAssignerActive && (
              <MassAssignerHeader reloadData={fetchProperties} />
            )}
            <div
              className={`flex flex-row w-full ${
                isMassAssignerActive ? '-h-24' : 'h-full'
              }`}
            >
              <div className={`w-${fftWidth} h-full`}>
                {selectedProperty?.isEditing ? (
                  formPanel[selectedProperty.type]
                ) : (
                  <PropertiesTree
                    openCreateModal={openCreateModalFn}
                    properties={filteredProperties}
                    rawProperties={propertyLandingPageData?.properties ?? []}
                    memberRole={loggedInUserOrgPermission?.role}
                  />
                )}
              </div>
              <div
                className="h-full relative flex flex-col"
                style={{ width: `calc(100% - ${fftWidth})` }}
              >
                {displayHeader && (
                  <div
                    className={
                      viewMode !== PROPERTY_VIEW_MODE.MAP
                        ? 'w-full'
                        : 'absolute z-40 bg-white w-full'
                    }
                  >
                    <PropertyTopHeader
                      viewMode={viewMode}
                      edited={edited}
                      setCancelEditModalVisible={setCancelEditModalVisible}
                      enableEdit={enableEdit}
                      setEnableEdit={setEnableEdit}
                      properties={filteredProperties}
                      selectedCrop={selectedCrop}
                      setSelectedCrop={setSelectedCrop}
                    />
                  </div>
                )}

                {isCostAnalysisViewEnabled &&
                  viewMode === PROPERTY_VIEW_MODE.COST && (
                    <PropertyCostAnalysis selectedCrop={selectedCrop} />
                  )}

                {viewMode === PROPERTY_VIEW_MODE.MAP && (
                  <div className="relative h-full">
                    {displayHeader &&
                      !isCostAnalysisViewEnabled &&
                      isPropertyDetailsEnabled && (
                        <PropertyContributionMargin />
                      )}
                    <PropertyLandingMap
                      properties={localSelectedProperty}
                      fieldsLoading={isLoadingFields}
                      propertyCount={organization.properties?.propertyCount}
                      crops={crops}
                    />
                  </div>
                )}
                {viewMode === PROPERTY_VIEW_MODE.INFO && (
                  <PropertyTable
                    setCancelEditModalVisible={setCancelEditModalVisible}
                    edited={edited}
                    setEdited={setEdited}
                    enableEdit={enableEdit}
                    setEnableEdit={setEnableEdit}
                  />
                )}
              </div>
              <ImportPropertyModal
                open={showImportModal}
                close={onUploadFileMode}
                reload={fetchProperties}
                importedFields={importedFields}
              />
            </div>
          </div>
        )}
        <SimpleModal
          open={cancelEditModalVisible}
          close={() => setCancelEditModalVisible(false)}
          confirmLabel="Exit Form"
          onCancel={() => {
            setCancelEditModalVisible(false);
          }}
          onConfirm={() => {
            setEnableEdit(false);
            setCancelEditModalVisible(false);
          }}
          title="Leaving Form"
          type="error"
        >
          <span>
            Navigating away from this form will discard all unsaved changes.
          </span>
        </SimpleModal>
      </div>
    </>
  );
};

export default PropertiesLanding;
